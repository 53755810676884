import React, { useState } from "react";

import Home from "./Home";

import Splash from "./Splash";
import { sessionStorageName } from "./Types";
import ThankYou from "./ThankYou";

enum FormStates {
  Splash,
  Form,
  ThankYou,
}

export const Main: React.FC = () => {
  // hooks
  // states
  const [useTransit, setUseTransit] = useState<FormStates>(() =>
    window.sessionStorage.getItem(sessionStorageName) ? FormStates.Form : FormStates.Splash
  );
  // parameter
  // queries und mutationen
  // effekte
  // daten
  const handleSubmit = () => setUseTransit(FormStates.Form);
  const handleCancel = () => {
    window.sessionStorage.removeItem(sessionStorageName);
    setUseTransit(FormStates.Splash);
  };
  const handleFormSubmit = () => setUseTransit(FormStates.ThankYou);

  switch (useTransit) {
    case FormStates.Splash:
      return <Splash onSubmit={handleSubmit} />;
    case FormStates.Form:
      return <Home onCancel={handleCancel} onFormSubmit={handleFormSubmit} />;
    case FormStates.ThankYou:
      return <ThankYou />;
  }
};

export default Main;
