import React from "react";
import { Route, Switch } from "react-router-dom";

import { ReactQueryDevtools } from "react-query/devtools";
import { QueryClient, QueryClientProvider } from "react-query";

import "@gelsenwasser/react/lib/Styles/gwag-theme.scss";
import "react-bootstrap-typeahead/css/Typeahead.css";

import { NotificationProvider } from "@gelsenwasser/react";

// static components
import Layout from "./components/Layout";
import Main from "./domain/Main";
import NoMatch from "./domain/NoMatch";
import { ApiContextProvider } from "./services/ApiContext";
import { KommunenContextProvider } from "./domain/KommunenContext";

const queryClient = new QueryClient();
export default class App extends React.Component {
  static displayName = App.name;

  render(): JSX.Element {
    return (
      <>
        <ApiContextProvider>
          <NotificationProvider>
            <KommunenContextProvider>
              <QueryClientProvider client={queryClient}>
                <Layout>
                  <Switch>
                    {/* Home */}
                    <Route exact path="/" component={Main} />
                    {/* Kundenbereich */}
                    {/* Unbekannte Seite */}
                    <Route path="*" component={NoMatch} />
                  </Switch>
                </Layout>
                <ReactQueryDevtools initialIsOpen={false} />
              </QueryClientProvider>
            </KommunenContextProvider>
          </NotificationProvider>
        </ApiContextProvider>
      </>
    );
  }
}
