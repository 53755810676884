import React, { useState } from "react";
import styles from "./HelperComponent.module.scss";
import HelpIcon from "./sogehts.svg";

const HelperComponent: React.FC = (props) => {
  // hooks
  // states
  const [currentHelperTextClass, setHelperTextClass] = useState(styles.helperTextClose);
  // parameter
  // queries und mutationen
  // effekte
  // daten
  // handler

  const openHelperText = () => {
    setHelperTextClass(styles.helperTextOpen);
  };
  const closeHelperText = () => {
    setHelperTextClass(styles.helperTextClose);
  };
  return (
    <>
      <div className={styles.helperContainer} onMouseEnter={openHelperText} onMouseLeave={closeHelperText}>
        <img src={HelpIcon} className={styles.helperIcon} />
        <div className={`shadow ${styles.helperText} ${currentHelperTextClass}`}>
          <div className={styles.helperTextInner}>{props.children}</div>
        </div>
      </div>
    </>
  );
};

export default HelperComponent;
