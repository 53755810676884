import React from "react";
import { Container, Row, Col, Jumbotron } from "react-bootstrap";
import { backgroundStyles } from "styles/splashScreenStyles";
import { useKommune } from "./KommunenContext";

const ThankYou: React.VFC = () => {
  const { kommune } = useKommune();

  return (
    <div style={backgroundStyles} className="h-100">
      <Container>
        <Row style={{ marginTop: "10rem" }}>
          <Col md={{ span: 6, offset: 2 }} lg={{ span: 6, offset: 2 }}>
            <Jumbotron>
              <h1 className="display-4">Vielen Dank für Ihre Anfrage</h1>
              <p>Wir haben Ihre Anfrage erhalten und senden Ihnen umgehen eine Bestätigungsmail.</p>
              <p>
                Sie bekommen die Tage zeitnah eine Antwort auf ihre Anfrage per E-Mail mit detaillierten Informationen
                zu Ihrer Anfrage.
              </p>
              <p>
                Sollten Sie nach unserer Überprüfung noch Fragen haben, wenden Sie sich per E-Mail an:{" "}
                <a href={"mailto:" + kommune?.kontaktEmail}>{kommune?.kontaktEmail}</a>
              </p>
              <p>
                Mit Freundlichen Grüßen
                <br />
                Ihr Team von
                <br />
                {kommune?.gesellschaftsName}
              </p>
            </Jumbotron>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ThankYou;
