import { FormikCheck, FormSection } from "@gelsenwasser/react";
import { useField, useFormikContext } from "formik";
import React, { useEffect, useRef } from "react";
import { Row, Col, Form, Card } from "react-bootstrap";
import { Anfrage, SchutzTypen } from "../Types";

import classNames from "classnames";

const cardClasses = "border-primary h-100 p-4";
const cardBodyClasses = "d-flex flex-column flex-grow-1 text-center justify-content-around";

const LoeschwassermengeStep: React.VFC = () => {
  // hooks
  const { values, setFieldValue, setFieldTouched } = useFormikContext<Anfrage>();
  const customInput = useRef<HTMLInputElement>(null);
  const [field, meta] = useField("loeschwasserMenge.menge");

  // states
  // parameter
  // queries und mutationen
  // effekte
  useEffect(() => {
    if (values.loeschwasserMenge.gewaehlteMenge !== "customMenge" && customInput.current) resetMenge();
  }, []);

  // daten
  let fieldError = meta.error;
  let showError = meta.touched && !!fieldError;
  const isObjektschutz = values.loeschwasserMenge.schutzTyp === SchutzTypen.Objektschutz;

  // handler
  const handleFocus = () => setFieldValue("loeschwasserMenge.gewaehlteMenge", "customMenge");

  const resetMenge = () => {
    if (customInput?.current) {
      customInput.current.value = "";
    }

    if (values.loeschwasserMenge.menge) {
      setFieldValue("loeschwasserMenge.menge", "");
      setFieldTouched("loeschwasserMenge.menge", true, true);
    }
    showError = false;
    fieldError = undefined;
  };

  return (
    <FormSection title="">
      <p>
        Bitte wählen Sie Ihren Schutzbereich und im Anschluss je nach baulicher Situation Ihren Löschwasserbedarf
        jeweils für die Dauer von mind. 2 Stunden aus.
      </p>
      <Row>
        <Col md={{ span: 5, offset: 1 }}>
          <Card
            className={classNames(cardClasses, {
              "bg-secondary": values.loeschwasserMenge.schutzTyp === SchutzTypen.Grundschutz,
              "bg-light": values.loeschwasserMenge.schutzTyp !== SchutzTypen.Grundschutz,
            })}
            onClick={() => setFieldValue("loeschwasserMenge.schutzTyp", SchutzTypen.Grundschutz)}
          >
            <Card.Body className={cardBodyClasses}>
              <h3>Grundschutz</h3>
              <p>Die Bereitstellung von Löschwasser erfolgt über öffentliche Hydranten</p>
              <FormikCheck
                value={SchutzTypen.Grundschutz}
                name="loeschwasserMenge.schutzTyp"
                label=""
                type="checkbox"
                id="schutzTyp_0"
                onChange={(e) => {
                  if (e.target.checked) {
                    setFieldValue("loeschwasserMenge.schutzTyp", SchutzTypen.Grundschutz);
                  }
                }}
              />
            </Card.Body>
          </Card>
        </Col>
        <Col md={{ span: 5 }}>
          <Card
            className={classNames(cardClasses, {
              "bg-secondary": values.loeschwasserMenge.schutzTyp === SchutzTypen.Objektschutz,
              "bg-light": values.loeschwasserMenge.schutzTyp !== SchutzTypen.Objektschutz,
            })}
            onClick={() => {
              setFieldValue("loeschwasserMenge.schutzTyp", SchutzTypen.Objektschutz);
              setFieldValue("loeschwasserMenge.gewaehlteMenge", "customMenge");
            }}
          >
            <Card.Body className={cardBodyClasses}>
              <div id="objektSchutzLabel">
                <h3>Objektschutz</h3>
                <p>
                  Die Bereitstellung von Löschwasser erfolgt über die Anschlussleitung des Objektes.
                  <br />
                  Hierzu ist ein gesonderter Objektschutzvertrag notwendig. Für diese Bereitstellung können Kosten
                  erhoben werden
                </p>
              </div>
              <FormikCheck
                aria-labelledby="objektSchutzLabel"
                value={SchutzTypen.Objektschutz}
                name="loeschwasserMenge.schutzTyp"
                label=""
                type="checkbox"
                id="schutzTyp_1"
                onChange={(e) => {
                  if (e.target.checked) {
                    setFieldValue("loeschwasserMenge.schutzTyp", SchutzTypen.Objektschutz);
                    setFieldValue("loeschwasserMenge.gewaehlteMenge", "customMenge");
                  }
                }}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col md={{ span: 11, offset: 1 }} hidden={isObjektschutz}>
          <FormikCheck
            name="loeschwasserMenge.gewaehlteMenge"
            label="48 m³/h Benötigte Löschwassermenge nach DVGW-W 405 über 2 Std. (Grundschutz)"
            type="checkbox"
            id="menge_0"
            value="48"
            onChange={(e) => {
              if (e.target.checked) {
                setFieldValue("loeschwasserMenge.gewaehlteMenge", "48");
                resetMenge();
              }
            }}
          />
        </Col>
        <Col md={{ span: 11, offset: 1 }} hidden={isObjektschutz}>
          <FormikCheck
            name="loeschwasserMenge.gewaehlteMenge"
            label="96 m³/h Benötigte Löschwassermenge nach DVGW-W 405 über 2 Std. (Grundschutz)"
            type="checkbox"
            id="menge_1"
            value="96"
            onChange={(e) => {
              if (e.target.checked) {
                setFieldValue("loeschwasserMenge.gewaehlteMenge", "96");
                resetMenge();
              }
            }}
          />
        </Col>
        <Col md={{ span: 11, offset: 1 }}>
          <FormikCheck
            name="loeschwasserMenge.gewaehlteMenge"
            label={
              <Form.Group className="form-inline">
                <Form.Control
                  type="text"
                  isInvalid={showError}
                  {...field}
                  ref={customInput}
                  placeholder="1-192"
                  name="loeschwasserMenge.menge"
                  onFocus={handleFocus}
                />
                <Form.Label className="pl-2">m³/h Benötigte Löschwassermenge nach DVGW-W 405 über 2 Std. (Grundschutz)</Form.Label>
                <Form.Control.Feedback type="invalid">{fieldError}</Form.Control.Feedback>
              </Form.Group>
            }
            type="checkbox"
            id="menge_2"
            value="customMenge"
            onChange={(e) => {
              if (e.target.checked) {
                setFieldValue("loeschwasserMenge.gewaehlteMenge", "customMenge");
              }
            }}
          />
        </Col>
      </Row>
    </FormSection>
  );
};

export default LoeschwassermengeStep;
