import React from "react";
import { BsBoxArrowUpRight } from "react-icons/bs";
import ModalHelpDialog, { ModalHelpParagraph } from "../../components/ModalHelpDialog";

interface LoeschwasserHelpProps {
  showDialog: boolean;
  handleClose: () => void;
}

const LoeschwasserHelp: React.FC<LoeschwasserHelpProps> = ({ showDialog, handleClose }) => {
  return (
    <ModalHelpDialog
      handleClose={handleClose}
      showDialog={showDialog}
      title="Welche Löschwassermenge zu welchen Gebiet und Objekt?"
    >
      <ModalHelpParagraph title="Grundschutz">
        Brandschutz für Wohngebiete, Gewerbegebiete, Mischgebiete und Industriegebiete ohne erhöhtes Sach- oder
        Personenrisiko.
      </ModalHelpParagraph>
      <ModalHelpParagraph title="Objektschutz">
        Über den Grundschutz hinausgehender, objektbezogener Brandschutz, zum Beispiel
      </ModalHelpParagraph>
      <ul>
        <li>
          für große Objekte mit erhöhtem Brandrisiko, z.B. zur Herstellung, Verarbeitung und Lagerung brennbarer oder
          leicht entzündbarer Stoffe
        </li>
        <li>
          für Objekte mit erhöhtem Personenrisiko, z.B. Versammlungsstätten, Verkaufsstätten, Krankenhäuser, Hotels,
          Hochhäuser
        </li>
        <li>
          für sonstige Einzelobjekte in Außenbereichen, wie Aussiedlerhöfe, Raststätten, Kleinsiedlungen,
          Wochenendhäuser
        </li>
      </ul>
      <ModalHelpParagraph>
        Die Bereitstellung von Löschwasser durch die öffentliche Trinkwasserversorgung ist in dem DVGW Arbeitsblatt W
        405 geregelt.
      </ModalHelpParagraph>
      <ModalHelpParagraph title="DVGW Arbeitsblatt W 405" />
      <iframe
        className="d-none d-sm-none d-md-block"
        src="./files/W 405 Deckblatt und Impressum.pdf"
        width="100%"
        height="500px"
      >
        Dieser Browser unterstützt nicht die Ansicht von PDFs.
      </iframe>
      <a
        className="d-md-none"
        href="./files/W 405 Deckblatt und Impressum.pdf"
        target="_blank"
      >
        In neuem Fenster öffnen{" "}
        <small>
          <BsBoxArrowUpRight />
        </small>
      </a>
    </ModalHelpDialog>
  );
};

export default LoeschwasserHelp;
