import React from "react";
import classNames from "classnames/bind";

import styles from "./ProcessStepper.module.scss";

const cx = classNames.bind(styles);

export interface ProcessStep {
  key: string;
  title: string;
  url?: string;
  imgUrl?: string;
  imgUrlActive?: string;
  modalHelp?: boolean;
  helpMenuTitle?: string;
  helpMenuText?: string;
}
export interface ProcessStepperProps {
  steps: Array<ProcessStep>;
  currentStep: string;
  isInvalid?: boolean;
  isCompleted?: boolean;
}

const ProcessStepper: React.VFC<ProcessStepperProps> = ({
  steps,
  currentStep,
  isInvalid = false,
  isCompleted = false,
}: ProcessStepperProps) => {
  const activeIdx = isCompleted ? steps.length : steps.findIndex((item) => item.key === currentStep);

  return (
    <div className={styles.Steps__Wrapper + " d-print-none my-4"}>
      <ol className={styles.Steps}>
        {steps.map((item, index) => {
          const isLastStep = index === steps.length - 1;
          const completed = index < activeIdx;
          const stepWidth = 100 / steps.length;
          const isCurrent = index === activeIdx;

          const stepClasses = cx({
            Step: true,
            Step__Last: isLastStep,
            Step__Current: isCurrent && !isInvalid,
            Step__Invalid: isCurrent && isInvalid,
            Step__Completed: completed,
          });

          const imgToUse = completed || isCurrent ? item.imgUrlActive : item.imgUrl;

          return (
            <li style={{ left: `${stepWidth / 4}%` }} key={item.key} className={stepClasses}>
              <div className="d-flex stepIcon flex-column">
                <img className={styles.ImageIcon} src={`${imgToUse}`} />
                <span className={styles.Step__Label}>{item.title}</span>
              </div>
            </li>
          );
        })}
      </ol>
    </div>
  );
};

export default ProcessStepper;
