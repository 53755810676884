import React from "react";
import { Button, Modal } from "react-bootstrap";

import styles from "./ModalHelpDialog.module.scss";

interface ModalHelpDialogProps {
  title: string;
  showDialog: boolean;
  handleClose: () => void;
}

interface ModalHelpParagraphProps {
  title?: string;
}

export const ModalHelpParagraph: React.FC<ModalHelpParagraphProps> = ({ title, children }) => {
  return (
    <>
      {title && <strong>{title}</strong>}
      <p>{children}</p>
    </>
  );
};

const ModalHelpDialog: React.FC<ModalHelpDialogProps> = ({ title, showDialog, handleClose, children }) => {
  return (
    <Modal show={showDialog} onHide={handleClose} centered dialogClassName={styles.modal80w}>
      <Modal.Header closeButton className="px-4">
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="px-4">{children}</Modal.Body>
      <Modal.Footer className="px-4">
        <Button onClick={handleClose}>Schließen</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalHelpDialog;
