import React, { useContext, useEffect, useState } from "react";
import { Branding, Kommune, sessionBrandingName, sessionKommuneName } from "./Types";

export interface KommunenContextProps {
  kommune: Kommune | null;
  branding: Branding | null;
  switchKommune: (newKommune: Kommune) => unknown;
  switchBranding: (newBranding: Branding) => unknown;
}

export const KommunenContext = React.createContext<KommunenContextProps>({
  kommune: null,
  branding: null,
  switchKommune: (_newKommune: Kommune) => null,
  switchBranding: (_newBranding: Branding) => null,
});

export const KommunenContextProvider: React.FC = (props) => {
  // hooks
  // states
  const [kommune, setKommune] = useState<Kommune | null>(null);
  const [branding, setBranding] = useState<Branding | null>(null);

  // parameter
  // queries und mutationen
  // effekte
  useEffect(() => {
    const storedKommune = window.sessionStorage.getItem(sessionKommuneName);
    if (storedKommune) setKommune(JSON.parse(storedKommune));

    const storedBranding = window.sessionStorage.getItem(sessionBrandingName);
    if (storedBranding) setBranding(JSON.parse(storedBranding));
  }, []);

  // daten
  // handler
  const switchKommune = (newKommune: Kommune) => {
    window.sessionStorage.setItem(sessionKommuneName, JSON.stringify(newKommune));
    setKommune(newKommune);
  };
  const switchBranding = (newBranding: Branding) => {
    window.sessionStorage.setItem(sessionBrandingName, JSON.stringify(newBranding));
    setBranding(newBranding);
  };

  return (
    <KommunenContext.Provider
      value={{
        kommune: kommune,
        branding: branding,
        switchKommune,
        switchBranding,
      }}
    >
      {props.children}
    </KommunenContext.Provider>
  );
};

export const useKommune = () => {
  const ctx = useContext(KommunenContext);
  if (ctx === undefined) {
    throw new Error("useKommune muss in einem KommunenContext Provider genutzt werden");
  }
  return ctx;
};
