import kommunenData from '../data/kommunen.json'
export type KommuneData = {
  displayName?: string;
  kommunenName: string;
  hostname: string;
  datenschutzLink: string;
  impressumLink: string;
  bdTeamName: string;
  logo: string;
  kontaktEmail: string;
  gesellschaftsName: string;
  kuerzel: string;
  lat: number;
  lng: number;
  polygon: any;
};
export function kommunenMapper (value: KommuneData) {
  return { ...value, name: value.displayName || value.kommunenName }
}

export const kommunenJsonData = kommunenData.map(kommunenMapper)
// Now, in other files, you can just import kommunen Objects directly:
// import { kommunenObjects } from './kommunenUtil';
