import React from "react";
import { Row, Col } from "react-bootstrap";
import ModalHelpDialog, { ModalHelpParagraph } from "../../components/ModalHelpDialog";

interface BauprojektHelpProps {
  showDialog: boolean;
  handleClose: () => void;
}

const BauprojektHelp: React.FC<BauprojektHelpProps> = ({ showDialog, handleClose }) => {
  return (
    <ModalHelpDialog
      handleClose={handleClose}
      showDialog={showDialog}
      title="Gebiete gem. Baunutzungsverordnung (BauNVO)"
    >
      <Row>
        <Col>
          <ModalHelpParagraph title="Reines Wohngebiet (WR)">
            Baugebiet nach § 3 BauNVO, das nur dem Wohnen dient. Läden und nicht störende Handwerksbetriebe und kleine
            Betriebe des Beherbergungsgewerbes, die zur Deckung des täglichen Bedarfs der Wohnbevölkerung dienen, sowie
            soziale Einrichtungen sind ausnahmsweise zulassungsfähig.
          </ModalHelpParagraph>
          <ModalHelpParagraph title="Allgemeine Wohngebiete (WA)">
            Baugebiet, das nach § 4 BauNVO vorwiegend dem Wohnen dient. Neben Wohngebäuden sind hier Läden, Restaurants,
            nicht störende Handwerksbetriebe und Anlagen für kirchliche, kulturelle, gesundheitliche und soziale Zwecke
            zulässig.
          </ModalHelpParagraph>
          <ModalHelpParagraph title="Besondere Wohngebiete (WB)">
            Besondere Wohngebiete sind überwiegend bebaute Gebiete, die aufgrund ausgeübter Wohnnutzung und vorhandener
            sonstiger in Absatz 2 genannter Anlagen eine besondere Eigenart aufweisen und in denen unter
            Berücksichtigung dieser Eigenart die Wohnnutzung erhalten und fortentwickelt werden soll.
          </ModalHelpParagraph>
          <ModalHelpParagraph title="Mischgebiet (MI)">
            Baugebiet, das nach § 6 BauNVO dem Wohnen und der Unterbringung von Gewerbebetrieben dient, die das Wohnen
            nicht wesentlich stören.
          </ModalHelpParagraph>
        </Col>
        <Col>
          <ModalHelpParagraph title="Dorfgebiet (MD)">
            Ein in dem § 5 BauNVO definiertes Baugebiet. Es dient der Unterbringung land- und forstwirtschaftlicher
            Betriebe, sowie der dazugehörigen Gewerbe- und Handwerksbetriebe.
          </ModalHelpParagraph>
          <ModalHelpParagraph title="Gewerbegebiet (GE)">
            Baugebiet, das der Unterbringung von nicht erheblich belastenden Gewerbebetrieben dient (8 BauNVO). Daneben
            sind Geschäfts-, Büro- und Verwaltungsgebäude, Tankstellen und Vergnügungsstätten zulässig.
          </ModalHelpParagraph>
          <ModalHelpParagraph title="Industriegebiet (GI)">
            Ein in dem § 9 BauNVO vorgesehener Typ von Baugebieten, der ausschließlich der Unterbringung von
            Gewerbebetrieben dient, vorwiegend solchen, die in anderen Baugebieten unzulässig sind, also auch erheblich
            belästigende Anlagen. Daneben sind Tankstellen allgemein, sonstige bauliche Anlagen nur ausnahmsweise
            zulässig.
          </ModalHelpParagraph>
          <ModalHelpParagraph title="Sondergebiet (SO)">
            Ist ein Baugebiet, welches sich nach § 10, § 11 der BauNVO von den anderen Arten von Baugebieten dadurch
            unterscheidet, dass hier die Nutzung zweckgebunden ist. In der Regel handelt es sich hierbei um Nutzungen
            aus dem Gebiet des Einzelhandels, des Fremdenverkehrs, der Kultur, der Wirtschaft, des Bildungs- oder
            Gesundheitswesens, der Energiegewinnung oder der Erholung. Hierunter fallen bspw. große Sportstadien,
            Messegelände oder Kraftwerke.
          </ModalHelpParagraph>
        </Col>
      </Row>
    </ModalHelpDialog>
  );
};

export default BauprojektHelp;
