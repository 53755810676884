import { FormikCheck, FormikControl, FormSection, NotificationType, useNotification } from "@gelsenwasser/react";
import { useFormikContext } from "formik";
import React, { useCallback } from "react";
import { Container, Col, Row, ListGroup } from "react-bootstrap";
import { FileRejection, useDropzone } from "react-dropzone";
import { BsFileEarmark } from "react-icons/bs";
import { log } from "../../common/Logger";
import { Anfrage, BebauungOptions, VerwendungszweckOptions, WohngebietOptions } from "../Types";
import UploadIcon from "../../assets/Upload.svg";

const UploadStyles: React.CSSProperties = {
  borderStyle: "dotted",
  transition: "border .24s ease-in-out",
  outline: "none",
  color: "#bdbdbd",
  backgroundColor: "#fafafa",
  borderWidth: "2px",
  borderRadius: "2px",
  height: "150px",
};

const base64RegExp = /data:\w*\/[\w.-]*;base64,/gm;

const toBase64 = (file: File) =>
  new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
    reader.readAsDataURL(file);
  });

const BauprojektStep: React.VFC = () => {
  // hooks
  const { values, setFieldValue } = useFormikContext<Anfrage>();
  const { addNotification } = useNotification();

  // states
  // parameter
  // queries und mutationen
  // effekte
  // daten
  const newColIndexWohngebiet = Math.round(WohngebietOptions.length / 2);

  const leftWohngebietOptions = WohngebietOptions.slice(0, newColIndexWohngebiet);
  const rightWohngebietOptions = WohngebietOptions.slice(newColIndexWohngebiet);

  // handler
  const handleDrop = async (acceptedFiles: Array<File>) => {
    const tempFiles = [...values.bauprojekt.tempFiles, ...acceptedFiles];
    setFieldValue("bauprojekt.tempFiles", tempFiles);

    const fileBinaries = await Promise.all(
      acceptedFiles.map(async (datei) => {
        const base64String = (await toBase64(datei)).replace(base64RegExp, "");

        return {
          fileName: datei.name,
          body: base64String,
        };
      })
    );
    const files = [...values.bauprojekt.dateien, ...fileBinaries];
    setFieldValue("bauprojekt.dateien", files);
  };
  const handleDropRejected = useCallback(
    (rejections: FileRejection[]) => {
      log.error({ obj: rejections }, "file-types rejected");
      const files = rejections.map((x) => x.file.name).join(", ");
      addNotification(files, "Dateien nicht unterstützt, bitte nur PNG, JPEG oder PDF", NotificationType.Alert);
    },
    [addNotification]
  );
  const handleChangeNumberSelection = (e: React.ChangeEvent<HTMLInputElement>, fieldName: string) => {
    log.debug(`Setting field '${fieldName}' to value '${e.target.value}'`);
    if (e.target.value) {
      setFieldValue(fieldName, parseInt(e.target.value, 10));
    } else {
      setFieldValue(fieldName, 0);
    }
  };
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDropRejected: handleDropRejected,
    onDrop: handleDrop,
    accept: ["image/jpeg", "image/png", "application/pdf"],
  });

  return (
    <FormSection title="">
      <Row>
        <Col md={6}>
          <Container>
            <Row>
              <Col>Angaben zum Wohngebiet</Col>
            </Row>
            <Row role="group">
              <Col md={6}>
                {leftWohngebietOptions.map((option, idx) => {
                  return (
                    <FormikCheck
                      type="radio"
                      name="bauprojekt.angabeWohngebiet"
                      label={option.value}
                      id={`${idx}_wohngebiet_${option.key}`}
                      value={option.key}
                      checked={option.key === values.bauprojekt.angabeWohngebiet}
                      key={`${idx}_${option.key}`}
                      onChange={(e) => {
                        handleChangeNumberSelection(e, "bauprojekt.angabeWohngebiet");
                      }}
                    />
                  );
                })}
              </Col>
              <Col md={6}>
                {rightWohngebietOptions.map((option, idx) => {
                  return (
                    <FormikCheck
                      type="radio"
                      name="bauprojekt.angabeWohngebiet"
                      label={option.value}
                      id={`${idx}_wohngebiet_${option.key}`}
                      value={option.key}
                      checked={option.key === values.bauprojekt.angabeWohngebiet}
                      key={`${idx}_${option.key}`}
                      onChange={(e) => {
                        handleChangeNumberSelection(e, "bauprojekt.angabeWohngebiet");
                      }}
                    />
                  );
                })}
              </Col>
            </Row>
          </Container>
        </Col>
        <Col md={3}>
          <Container>
            <Row>
              <Col>Angaben zur Bebauung</Col>
            </Row>
            <Row>
              <Col>
                {BebauungOptions.map((option, idx) => {
                  return (
                    <FormikCheck
                      type="radio"
                      name="bauprojekt.angabeBebauung"
                      label={option.value}
                      id={`${idx}_bebauung_${option.key}`}
                      value={option.key}
                      checked={option.key === values.bauprojekt.angabeBebauung}
                      key={`${idx}_${option.key}`}
                      onChange={(e) => {
                        handleChangeNumberSelection(e, "bauprojekt.angabeBebauung");
                      }}
                    />
                  );
                })}
              </Col>
            </Row>
          </Container>
        </Col>
        <Col md={3}>
          <Container>
            <Row>
              <Col>Verwendungszweck</Col>
            </Row>
            <Row>
              <Col>
                {VerwendungszweckOptions.map((option, idx) => {
                  return (
                    <FormikCheck
                      type="radio"
                      name="bauprojekt.angabeVerwendungszweck"
                      label={option.value}
                      id={`${idx}_${option.key}`}
                      value={option.key}
                      checked={option.key === values.bauprojekt.angabeVerwendungszweck}
                      key={`${idx}_bebauung_${option.key}`}
                      onChange={(e) => {
                        handleChangeNumberSelection(e, "bauprojekt.angabeVerwendungszweck");
                      }}
                    />
                  );
                })}
              </Col>
            </Row>
          </Container>
        </Col>
      </Row>
      <Row>
        <Col>
          <Container>
            <Row>
              <Col md={{ span: 3, offset: 6 }}>
                {values.bauprojekt.angabeBebauung === BebauungOptions[BebauungOptions.length - 1].key && (
                  <Container>
                    <Row>
                      <Col md={12}>
                        <FormikControl name="bauprojekt.angabeBebauungFreitext" label="Freitext" as="textarea" />
                      </Col>
                    </Row>
                  </Container>
                )}
              </Col>
              <Col md={3}>
                <Container>
                  {values.bauprojekt.angabeVerwendungszweck ===
                    VerwendungszweckOptions[VerwendungszweckOptions.length - 1].key && (
                    <Row>
                      <Col>
                        <FormikControl
                          name="bauprojekt.angabeVerwendungszweckFreitext"
                          label="Freitext"
                          as="textarea"
                        />
                      </Col>
                    </Row>
                  )}
                  <Row>
                    <Col md={12}>
                      <span
                        style={{
                          color: "red",
                        }}
                      >
                        * Behördliche Auflagen/Unterlagen sind mit einzureichen
                      </span>
                    </Col>
                  </Row>
                </Container>
              </Col>
            </Row>
          </Container>
        </Col>
      </Row>
      <Row>
        <Col>
          <Container>
            <Row>
              <Col md={12}>
                <FormikControl name="bauprojekt.bemerkung" label="Bemerkung" as="textarea" rows={3} />
              </Col>
            </Row>
          </Container>
        </Col>
      </Row>
      <Row>
        <Col>
          <div
            className="border-primary d-flex flex-column justify-content-center align-items-center text-center mb-4 p-3"
            style={UploadStyles}
            {...getRootProps()}
          >
            <input {...getInputProps()} />
            {isDragActive ? (
              <h4>Dateien hierher ziehen ...</h4>
            ) : (
              <>
                <div>
                  <h5 className="primary">
                    Bitte laden Sie die folgenden Unterlagen (Lageplan, Flurplan) als Datei hoch.
                  </h5>
                  (nur PNG, JPG oder PDF; max 5 MB)
                </div>
                <img src={UploadIcon} width="27px" height="22px" />
              </>
            )}
          </div>
          <ListGroup>
            {values.bauprojekt.dateien?.map((item, idx) => (
              <ListGroup.Item key={idx}>
                <BsFileEarmark /> {item.fileName}
              </ListGroup.Item>
            ))}
          </ListGroup>
        </Col>
      </Row>
    </FormSection>
  );
};

export default BauprojektStep;
