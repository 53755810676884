import React, { useEffect, useState } from "react";
import { Button, Jumbotron, Row, Col, Container } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import { BsArrowRight } from "react-icons/bs";
import { backgroundStyles } from "styles/splashScreenStyles";
import { useKommune } from "./KommunenContext";
import GwLogo from "../assets/gw_logo.png";

import { log } from "../common/Logger";
import { kommunenJsonData } from "../common/kommuneUtil";

interface SplashProps {
  onSubmit: () => void;
}

const Splash: React.FC<SplashProps> = ({ onSubmit: handleSubmit }) => {
  return (
    <div style={backgroundStyles} className="h-100">
      <Container>
        <Row style={{ marginTop: "10rem" }}>
          <Col md={8} lg={8}>
            <Jumbotron>
              <h1 className="display-4">
                Herzlich Willkommen im <strong>Lösch&shy;wasser&shy;auskunft</strong>&shy;Portal
              </h1>
              <p>
                Willkommen auf dem <strong>Löschwasserauskunft</strong>Portal der GELSENWASSER AG. Wir sind das Team
                blau-grün. Als Partner von Städten und Kommunen bieten wir Lösungen für alle Aufgaben und
                Herausforderungen der Infrastruktur.
              </p>
              <p>
                Bitte geben Sie für die Auskunft die Kommune an. Mit Hilfe der Ortsangabe können wir die für Sie
                passende zuständige Stelle angeben.
              </p>
              <OrtSelector onSubmit={handleSubmit} />
            </Jumbotron>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

// TODO: defaults für Branding
const defaults = {
  logoUrl: GwLogo,
  impressumLink: "",
  datenschutzLink: "",
};

const OrtSelector: React.FC<SplashProps> = ({ onSubmit }) => {
  // hooks
  const { switchKommune, switchBranding } = useKommune();
  // states
  const [kommunenOptions, setKommunenOptions] = useState<string[]>([]);
  const [kommuneSelected, setKommuneSelected] = useState<boolean>(false);
  // parameter
  // queries und mutationen
  // effekte
  useEffect(() => {
    const hostToCheck = window.location.hostname;
    log.debug(`host to check: ${hostToCheck}`);
    if (kommunenJsonData?.length) {
      const possibleKommunen = kommunenJsonData.filter((k) => {
        const result =
          process.env.REACT_APP_SHOW_ALL === "true" || (k.hostname.indexOf(hostToCheck) > -1 && !!k.hostname);
        return result;
      });
      const possibleOptions = possibleKommunen.map((k) => {
        return k.name;
      });
      setKommunenOptions(possibleOptions);
      switchBranding({
        datenschutzLink: possibleKommunen[0]?.datenschutzLink || defaults.datenschutzLink,
        gesellschaftsName: possibleKommunen[0]?.gesellschaftsName || "",
        impressumLink: possibleKommunen[0]?.impressumLink || defaults.impressumLink,
        kontaktEmail: possibleKommunen[0]?.kontaktEmail || "",
        logo: `./logos/${possibleKommunen[0]?.logo}` || defaults.logoUrl,
      });
    }
  }, []);
  // daten
  // handler

  // handler
  const handleChangeOrt = (kommune: string) => {
    const foundKommune = kommunenJsonData.filter((k) => k.name === kommune);
    if (foundKommune?.length) {
      switchKommune(foundKommune[0]);
    } else {
      switchKommune({
        logo: defaults.logoUrl,
        datenschutzLink: defaults.datenschutzLink,
        bdTeamName: "",
        gesellschaftsName: "",
        impressumLink: defaults.impressumLink,
        kommunenName: "",
        name: "",
        kontaktEmail: "",
        kuerzel: "",
        lat: 0.0,
        lng: 0.0,
        polygon: [],
      });
    }
  };

  return (
    <Container fluid className="px-0">
      <Row className="d-flex">
        <Col md={6} lg={8}>
          <Typeahead
            flip
            highlightOnlyResult
            id="ortSelector"
            placeholder="Auswahl"
            options={kommunenOptions ?? []}
            onChange={(selected) => {
              log.debug({ obj: selected }, "selected kommune");
              handleChangeOrt(selected[0]);
              setKommuneSelected(selected.length > 0);
            }}
          />
        </Col>
        <Col>
          <Button disabled={!kommuneSelected} variant="secondary" onClick={onSubmit}>
            {" "}
            <BsArrowRight className="text-primary" /> Weiter
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default Splash;
