import { useQuery, UseQueryResult } from "react-query";
import axios, { AxiosError } from "axios";

import { log } from "common/Logger";
import { useErrorLogger } from "common/LoggerHooks";
import { LatLng, latLng } from "leaflet";
import { NominatimResponse, NominatimReverseResponse, Projektstandort } from "domain/Types";

const defaultLookupStaleTime = 15 * 60 * 1000; /* 15 Minuten, Default: 0 Minuten */
const defaultLookupCacheTime = 5 * 60 * 1000; /* Default: 5 Minuten */

export const useGetPosition = (
  address?: Projektstandort,
  enabled = true
): UseQueryResult<LatLng | undefined, AxiosError> => {
  const { logAxiosError } = useErrorLogger();

  const SearchForLocation = async ({ queryKey }): Promise<LatLng | undefined> => {
    const [, address]: [never, Projektstandort | undefined] = queryKey;
    if (!address) {
      log.debug("no address was provided, returning undefined");
      return undefined;
    }

    log.debug({ obj: address }, "searching for address");
    const response = await axios.get<Array<NominatimResponse>>(
      `https://nominatim.openstreetmap.org/search?format=jsonv2&limit=1&countrycodes=de&country=Germany&street=${address.strasse} ${address.hausnummer}&city=${address.ort}&postalcode=${address.plz}`
    );
    log.trace({ obj: response.data }, "result from get SearchForLocation");

    if (response.data.length !== 1) {
      log.debug("didn't find any position for address, returning undefined");
      return undefined;
    }
    return latLng(response.data[0].lat, response.data[0].lon);
  };

  return useQuery<LatLng | undefined, AxiosError>({
    queryKey: ["Standort", address],
    queryFn: SearchForLocation,
    enabled: enabled,
    onError: (err) =>
      logAxiosError("Error location address for map", "Fehler beim Suchen der Adresse für die Kartendarstellung", err),
    staleTime: defaultLookupStaleTime,
    cacheTime: defaultLookupCacheTime,
  });
};

export const useGetAddress = (
  position: LatLng,
  enabled = true
): UseQueryResult<NominatimReverseResponse, AxiosError> => {
  const { logAxiosError } = useErrorLogger();

  const SearchForAddress = async ({ queryKey }): Promise<NominatimReverseResponse> => {
    const [, position]: [never, LatLng] = queryKey;

    log.debug({ obj: position }, "searching for position");
    const response = await axios.get<NominatimReverseResponse>(
      `https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${position.lat}&lon=${position.lng}`
    );
    log.trace({ obj: response.data }, "result from get SearchForAddress");

    return response.data;
  };

  return useQuery<NominatimReverseResponse, AxiosError>({
    queryKey: ["Standort", position],
    queryFn: SearchForAddress,
    enabled: enabled,
    onError: (err) =>
      logAxiosError("Error location address for map", "Fehler beim Suchen der Adresse für die Kartendarstellung", err),
    staleTime: 10,
    cacheTime: 10,
  });
};
