import { FormikCheck, FormSection } from "@gelsenwasser/react";
import { useKommune } from "domain/KommunenContext";
import { useFormikContext } from "formik";
import React, { useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { Anfrage, BebauungOptions, WohngebietOptions } from "../Types";

interface SummaryLabelProps {
  label: string;
  value: string;
}
const SummaryLabel: React.FC<SummaryLabelProps> = ({ label, value }) => {
  return (
    <>
      <Col md={2}>
        <strong>{label}</strong>
      </Col>
      <Col md={4}>{value}</Col>
    </>
  );
};

const formatOrtsangabe = (strasse: string, hausnummer: string, plz: string, ort: string): string => {
  return `${strasse} ${hausnummer}, ${plz} ${ort}`;
};

const AngabenPruefenStep: React.VFC = () => {
  // hooks
  const { values, setFieldValue } = useFormikContext<Anfrage>();
  const { kommune } = useKommune();

  // states
  // parameter
  // queries und mutationen
  // effekte
  useEffect(() => {
    // nur bei CustomMenge ist die Menge schon gesetzt; ansonsten muss die an dieser Stelle für die Übermittlung abgeleitet werden
    if (values.loeschwasserMenge.gewaehlteMenge !== "customMenge")
      setFieldValue("loeschwasserMenge.menge", parseInt(values.loeschwasserMenge.gewaehlteMenge));
    // da Formik die Menge immer als String speichert, muss das hier etwas umständlich in eine Zahl umgewandelt werden
    else setFieldValue("loeschwasserMenge.menge", parseInt(values.loeschwasserMenge.menge.toString()));
    setFieldValue("datenschutzAkzeptiert", false);
  }, []);

  // daten
  const angabeWohngebiet = WohngebietOptions.filter((w) => w.key === values.bauprojekt.angabeWohngebiet);
  const angabeBebauung = BebauungOptions.filter((b) => b.key === values.bauprojekt.angabeBebauung);

  // handler

  return (
    <FormSection title="">
      <p>Bitte überprüfen Sie vor Absenden des Formulars noch einmal Ihre Angaben:</p>
      <Row>
        <SummaryLabel label="Kommune:" value={kommune?.name ?? ""} />
        <SummaryLabel label="Gebiet gem. (BauNVO)" value={angabeWohngebiet[0]?.value ?? ""} />
      </Row>
      <Row>
        {values.projektstandort.hausnummer && (
          <SummaryLabel
            label="Standort:"
            value={formatOrtsangabe(
              values.projektstandort.strasse,
              values.projektstandort.hausnummer,
              values.projektstandort.plz,
              values.projektstandort.ort
            )}
          />
        )}
        {!values.projektstandort.hausnummer && (
          <SummaryLabel
            label="Standort"
            value={`Flur: ${values.projektstandort.flur}, Flurstück: ${values.projektstandort.flurstueck}`}
          />
        )}
        <SummaryLabel label="Bebauung" value={angabeBebauung[0]?.value ?? ""} />
      </Row>
      <Row>
        <SummaryLabel label="Unterlagen:" value={values.bauprojekt.dateien.map((x) => x.fileName).join(", ")} />
        <SummaryLabel label="Löschwassermenge:" value={`${values.loeschwasserMenge.menge} m³/h`} />
      </Row>
      <Row>
        <SummaryLabel
          label="Vor-, Nachname:"
          value={`${values.kontaktdaten.vorname} ${values.kontaktdaten.nachname}`}
        />
        <SummaryLabel label="Unternehmen:" value={values.kontaktdaten.unternehmen} />
      </Row>
      <Row>
        <SummaryLabel
          label="Adresse"
          value={formatOrtsangabe(
            values.kontaktdaten.strasse,
            values.kontaktdaten.hausnummer,
            values.kontaktdaten.plz,
            values.kontaktdaten.ort
          )}
        />
        <SummaryLabel label="Adresszusatz:" value={values.kontaktdaten.adresszusatz} />
      </Row>
      <Row>
        <SummaryLabel label="Telefon:" value={values.kontaktdaten.telefon} />
        <SummaryLabel label="E-Mail:" value={values.kontaktdaten.email} />
      </Row>
      <Row>
        <Col md={2}>
          <strong>Datenschutz:</strong>
        </Col>
        <Col md={9}>
          <FormikCheck
            id="datenSchutzAkzeptiert_0"
            type="checkbox"
            name="datenschutzAkzeptiert"
            label={
              <>
                * Die abgesendeten Daten werden nur zum Zweckder Bearbeitung Ihres Anliegens verarbeitet. Unsere
                Datenschutzhinweise und die Hinweise zu Ihrem datenschutzrechtlichen Wiederspruchrecht finden sie{" "}
                <a href={kommune?.datenschutzLink} target="_blank" rel="noreferrer">
                  hier
                </a>
                .
              </>
            }
          />
        </Col>
      </Row>
    </FormSection>
  );
};

export default AngabenPruefenStep;
