import * as Yup from "yup";
import { Shape } from "common/Validation";

import {
  Anfrage,
  AnredeOptions,
  Bauprojekt,
  BebauungOptions,
  Kontaktdaten,
  LoeschwasserMenge,
  Projektstandort,
  SchutzTypen,
  VerwendungszweckOptions,
  WohngebietOptions,
} from "./Types";

const maxCharacters = 100;
const maxCharErrorMsg = `Es können nicht mehr als ${maxCharacters} Zeichen angeben werden`;

const ProjektstandortSchema = Yup.object<Shape<Projektstandort>>({
  strasse: Yup.string().ensure().max(maxCharacters, maxCharErrorMsg),
  hausnummer: Yup.string().ensure().max(maxCharacters, maxCharErrorMsg),
  plz: Yup.string()
    .ensure()
    .max(maxCharacters, maxCharErrorMsg)
    .when({
      is: (val: string) => !!val,
      then: (schema) => schema.matches(/^\d{5}$/, { message: "Bitte genau 5 Ziffern angeben" }),
    }),
  ort: Yup.string().ensure().max(maxCharacters, maxCharErrorMsg),
  flur: Yup.string().ensure().max(maxCharacters, maxCharErrorMsg),
  flurstueck: Yup.string().ensure().max(maxCharacters, maxCharErrorMsg),
  latitude: Yup.number(), //.required("Kein Marker vorhanden"),
  longitude: Yup.number(), //.required("Kein Marker vorhanden"),
  karteAktiv: Yup.boolean().nullable().default(null),
})
  .test("flurOderAdresse", "Geben Sie bitte eine Adresse oder ein Flurstück an", (val) => {
    const flurAngegeben = val.flur && val.flurstueck;
    const adresseAngegeben = val.strasse && val.hausnummer && val.ort && !!val.plz;
    const isValid = (!flurAngegeben && adresseAngegeben) || flurAngegeben || val.karteAktiv;
    return isValid;
  })
  .test("koordinaten", "Adresse konnte nicht gefunden werden; ggf. Karte verwenden", (val) => {
    return val.latitude && val.longitude;
  });
const BauprojektSchema = Yup.object<Shape<Bauprojekt>>({
  angabeWohngebiet: Yup.number()
    .required("Wählen Sie bitte ein Wohngebiet")
    .oneOf(WohngebietOptions.map((opt) => opt.key)),
  angabeBebauung: Yup.number()
    .required("Wählen Sie bitte eine Bebauungsart")
    .oneOf(BebauungOptions.map((opt) => opt.key)),
  angabeBebauungFreitext: Yup.string().ensure(),
  angabeVerwendungszweck: Yup.number()
    .required("Wählen Sie bitte einen Verwendungszweck")
    .oneOf(VerwendungszweckOptions.map((opt) => opt.key)),
  angabeVerwendungszweckFreitext: Yup.string().ensure(),
  bemerkung: Yup.string().ensure(),
  tempFiles: Yup.array().ensure(),
  dateien: Yup.array().ensure(),
});

const LoeschwasserMengeSchema = Yup.object<Shape<LoeschwasserMenge>>({
  schutzTyp: Yup.string()
    .ensure()
    .required("Wählen Sie bitte einen Schutztypen")
    .default(SchutzTypen.Grundschutz)
    .oneOf([SchutzTypen.Grundschutz, SchutzTypen.Objektschutz]),
  menge: Yup.number()
    .nullable()
    .when("gewaehlteMenge", {
      is: "customMenge",
      then: (schema) =>
        schema
          .integer("Geben Sie bitte eine ganze Zahl ein")
          .min(1, "Geben Sie bitte eine Löschwasser Menge zwischen 1 und 192 ein")
          .max(192, "Geben Sie bitte eine Löschwasser Menge zwischen 1 und 192 ein")
          .required("Geben Sie bitte eine Löschwasser Menge zwischen 1 und 192 ein"),
    }),
  gewaehlteMenge: Yup.string()
    .ensure()
    .required("Wählen Sie bitte eine Löschwasser Menge")
    .when("schutzTyp", {
      is: (val) => val === SchutzTypen.Objektschutz,
      then: Yup.string()
        .required("Für den Objektschutz muss eine benutzerspezifische Menge angegeben werden")
        .oneOf(["customMenge"], "Für den Objektschutz muss eine benutzerspezifische Menge angegeben werden"),
      otherwise: Yup.string()
        .required("Wählen Sie bitte eine Löschwasser Menge")
        .oneOf(["48", "96", "customMenge"], "Wählen Sie bitte eine Löschwasser Menge"),
    }),
});

const KontaktdatenSchema = Yup.object<Shape<Kontaktdaten>>({
  anrede: Yup.number()
    .required("Wählen Sie bitte eine Anrede")
    .oneOf(
      AnredeOptions.map((opt) => opt.key),
      "Wählen Sie bitte eine Anrede"
    ),
  titel: Yup.number()
    .nullable()
    .transform((_, val) => (val === Number(val) ? val : null)),
  vorname: Yup.string().ensure().max(maxCharacters, maxCharErrorMsg).required("Geben Sie bitte einen Vornamen an"),
  nachname: Yup.string().ensure().max(maxCharacters, maxCharErrorMsg).required("Geben Sie bitte einen Nachnamen an"),
  unternehmen: Yup.string().max(maxCharacters, maxCharErrorMsg).ensure(),
  strasse: Yup.string().ensure().max(maxCharacters, maxCharErrorMsg).required("Geben Sie bitte eine Strasse an"),
  hausnummer: Yup.string().ensure().max(maxCharacters, maxCharErrorMsg).required("Geben Sie bitte eine Hausnummer an"),
  plz: Yup.string()
    .ensure()
    .required("Geben Sie bitte eine PLZ an")
    .matches(/^\d{5}$/, { message: "Bitte genau 5 Ziffern angeben" }),
  ort: Yup.string().ensure().max(maxCharacters, maxCharErrorMsg).required("Geben Sie bitte einen Ort an"),
  adresszusatz: Yup.string().ensure().max(maxCharacters, maxCharErrorMsg),
  telefon: Yup.string().max(maxCharacters, maxCharErrorMsg).ensure(),
  email: Yup.string()
    .ensure()
    .max(maxCharacters, maxCharErrorMsg)
    .email("Geben Sie bitte eine gültige E-Mail Adresse an")
    .required("Geben Sie bitte eine gültige E-Mail Adresse an"),
});

export const AnfrageSchema = Yup.object<Shape<Anfrage>>({
  kuerzel: Yup.string().ensure(),
  bezirksDirektion: Yup.string().ensure(),
  kommunenName: Yup.string().ensure(),
  name: Yup.string().ensure(),
  projektstandort: ProjektstandortSchema,
  bauprojekt: BauprojektSchema,
  loeschwasserMenge: LoeschwasserMengeSchema,
  kontaktdaten: KontaktdatenSchema,
  datenschutzAkzeptiert: Yup.boolean()
    .default(false)
    .oneOf([true], "Bitte geben Sie uns Ihre Zustimmung zur Verarbeitung ihrer Daten")
    .required("Bitte geben Sie uns Ihre Zustimmung zur Verarbeitung ihrer Daten"),
});
