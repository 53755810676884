import { latLng } from "leaflet";

export interface Kommune {
  kommunenName: string;
  name: string;
  logo: string;
  gesellschaftsName: string;
  impressumLink: string;
  datenschutzLink: string;
  kontaktEmail: string;
  bdTeamName: string;
  kuerzel: string;
  lat: number;
  lng: number;
  polygon: number[][];
}

export interface Branding {
  logo: string;
  gesellschaftsName: string;
  impressumLink: string;
  datenschutzLink: string;
  kontaktEmail: string;
}

export interface Anfrage {
  kommunenName: string;
  name: string;
  kuerzel: string;
  bezirksDirektion: string;
  projektstandort: Projektstandort;
  bauprojekt: Bauprojekt;
  loeschwasserMenge: LoeschwasserMenge;
  kontaktdaten: Kontaktdaten;
  datenschutzAkzeptiert: boolean;
}

export interface Projektstandort {
  strasse: string;
  hausnummer: string;
  plz: string;
  ort: string;
  flur: string;
  flurstueck: string;
  latitude: number;
  longitude: number;
  karteAktiv: boolean;
}
interface OptionSet {
  key: number;
  value: string;
}

export const BebauungOptions: OptionSet[] = [
  { key: 761440000, value: "Einfamilienhaus" },
  { key: 761440001, value: "Doppelhaushälfte" },
  { key: 761440002, value: "Wohnung" },
  { key: 761440003, value: "Gewerbe" },
  { key: 761440004, value: "Mehrfamilienhaus" },
  { key: 761440005, value: "... etwas anderes" },
];

export const VerwendungszweckOptions: OptionSet[] = [
  { key: 761440000, value: "Brandschutzkonzept" },
  { key: 761440001, value: "Nutzungsänderung" },
  { key: 761440002, value: "Neubau / Umbau / Erweiterung" },
  { key: 761440003, value: "Versicherungskonzept" },
  { key: 761440004, value: "... etwas anderes" },
];

export const WohngebietOptions: OptionSet[] = [
  { key: 761440000, value: "Reine Wohngebiete (WR)" },
  { key: 761440001, value: "Allgemeine Wohngebiete (WA)" },
  { key: 761440002, value: "besondere Wohngebiete (WB)" },
  { key: 761440003, value: "Mischgebiete (MI)" },
  { key: 761440004, value: "Dorfgebiete (MD)" },
  { key: 761440005, value: "Gewerbegebiete (GE)" },
  { key: 761440006, value: "Industriegebiete (GI)" },
  { key: 761440007, value: "Sondergebiete (SO)" },
  { key: 761440008, value: "nicht bekannt" },
];

export const AnredeOptions: OptionSet[] = [
  { key: 761440000, value: "Herr" },
  { key: 761440001, value: "Frau" },
  { key: 761440002, value: "Divers" },
];

export const TitelOptions: OptionSet[] = [
  { key: 0, value: "(Bitte wählen)" },
  { key: 761440000, value: "Dr." },
  { key: 761440001, value: "Prof." },
  { key: 761440002, value: "Prof. Dr." },
];

export interface Bauprojekt {
  angabeWohngebiet: number;
  angabeBebauung: number;
  angabeBebauungFreitext: string;
  angabeVerwendungszweck: number;
  angabeVerwendungszweckFreitext: string;
  bemerkung: string;
  tempFiles: File[];
  dateien: {
    fileName: string;
    body: string;
  }[];
}

export const SchutzTypen = {
  Grundschutz: "Grundschutz",
  Objektschutz: "Objektschutz",
};

export interface LoeschwasserMenge {
  schutzTyp: string;
  menge: number;
  gewaehlteMenge: "48" | "96" | "customMenge";
}

export interface Kontaktdaten {
  anrede: number;
  titel?: number;
  vorname: string;
  nachname: string;
  unternehmen: string;
  strasse: string;
  hausnummer: string;
  plz: string;
  ort: string;
  adresszusatz: string;
  telefon: string;
  email: string;
}

export const sessionStorageName = "loeschwasser-anfrage";
export const sessionKommuneName = "loeschwasser-kommune";
export const sessionBrandingName = "loeschwasser-branding";

// Gelsenkirchen
export const defaultMapCenter = latLng([51.546865, 7.068724]);

export interface NominatimResponse {
  lat: number;
  lon: number;
}

export interface NominatimReverseResponse {
  address: {
    road: string;
    city: string;
    town: string;
    village: string;
    postcode: string;
    house_number: string;
  };
}
