import React, { useState, useEffect } from "react";
import { BsArrowLeft, BsArrowRight } from "react-icons/bs";
import { Col, Row, Container, Button, Modal } from "react-bootstrap";
import { Formik, Form as FormikForm, FormikHelpers } from "formik";

import { ActionButton, ContentBox, useNotification } from "@gelsenwasser/react";

import { log } from "common/Logger";
import { ModalLoader } from "components/ZahnradLoader";
import ProcessStepper, { ProcessStep } from "components/ProcessStepper";
import { Anfrage, sessionStorageName } from "./Types";
import { AnfrageSchema } from "./Validation";
import ProjektstandortStep from "./steps/ProjektstandortStep";
import BauprojektStep from "./steps/BauprojektStep";
import LoeschwassermengeStep from "./steps/LoeschwassermengeStep";
import KontaktdatenStep from "./steps/KontaktdatenStep";
import AngabenPruefenStep from "./steps/AngabenPruefenStep";
import { useKommune } from "./KommunenContext";
import { useCreateAnfrage } from "../services/anfrageMutations";

import ProjektStandortIcon from "../assets/1-projektstandort.svg";
import ProjektStandortActiveIcon from "../assets/1-projektstandort-active.svg";
import BauProjektIcon from "../assets/2-bauprojekt.svg";
import BauProjektActiveIcon from "../assets/2-bauprojekt-active.svg";
import LoeschwassermengeIcon from "../assets/3-loeschwassermenge.svg";
import LoeschwassermengeActiveIcon from "../assets/3-loeschwassermenge-active.svg";
import KontaktdatenIcon from "../assets/4-kontaktdaten.svg";
import KontaktdatenActiveIcon from "../assets/4-kontaktdaten-active.svg";
import UberpruefenIcon from "../assets/5-uberpruefen.svg";
import UberpruefenActiveIcon from "../assets/5-uberpruefen-active.svg";
import { ObjectSchema } from "yup";
import { Shape } from "common/Validation";

import { Persist } from "common/Formik-Persist";
import HelperComponent from "../components/HelperComponent";
import BauprojektHelp from "./helptexts/BauprojektModalHelp";
import LoeschwasserHelp from "./helptexts/LoeschwasserModalHelp";

const steps: Array<ProcessStep> = [
  {
    key: "1",
    title: "Ihr Projektstandort",
    imgUrl: ProjektStandortIcon,
    imgUrlActive: ProjektStandortActiveIcon,
  },
  {
    key: "2",
    title: "Ihr Bauprojekt",
    imgUrl: BauProjektIcon,
    imgUrlActive: BauProjektActiveIcon,
    modalHelp: true,
    helpMenuTitle: "So geht's",
    helpMenuText: "Hilfstext Bauprojekt",
  },
  {
    key: "3",
    title: "Löschwassermenge",
    imgUrl: LoeschwassermengeIcon,
    imgUrlActive: LoeschwassermengeActiveIcon,
    modalHelp: true,
    helpMenuTitle: "So geht's",
    helpMenuText: "Hilfstext Löschwasser",
  },
  {
    key: "4",
    title: "Ihre Kontaktdaten",
    imgUrl: KontaktdatenIcon,
    imgUrlActive: KontaktdatenActiveIcon,
  },
  {
    key: "5",
    title: "Angaben überprüfen",
    imgUrl: UberpruefenIcon,
    imgUrlActive: UberpruefenActiveIcon,
  },
];
const stepForms: Array<{
  form: JSX.Element;
  schema: ObjectSchema<
    Shape<
      | Anfrage
      | Anfrage["projektstandort"]
      | Anfrage["bauprojekt"]
      | Anfrage["loeschwasserMenge"]
      | Anfrage["kontaktdaten"]
    >
  >;
  caption: string;
}> = [
  {
    form: <ProjektstandortStep />,
    schema: AnfrageSchema.pick(["projektstandort"]),
    caption: "Anschrift des Objektes für die Löschwassermenge",
  },
  {
    form: <BauprojektStep />,
    schema: AnfrageSchema.pick(["bauprojekt"]),
    caption: "Ihr Bauprojekt",
  },
  {
    form: <LoeschwassermengeStep />,
    schema: AnfrageSchema.pick(["loeschwasserMenge"]),
    caption: "Benötigte Löschwassermenge",
  },
  {
    form: <KontaktdatenStep />,
    schema: AnfrageSchema.pick(["kontaktdaten"]),
    caption: "Ihre Kontaktdaten",
  },
  {
    form: <AngabenPruefenStep />,
    schema: AnfrageSchema,
    caption: "Zusammenfassung",
  },
];

const emptyData: Anfrage = AnfrageSchema.getDefault();

interface HomeProps {
  onCancel: () => void;
  onFormSubmit: () => void;
}

export const Home: React.VFC<HomeProps> = (props: HomeProps) => {
  // hooks
  const { addNotification } = useNotification();
  const { kommune } = useKommune();

  // states
  const [activeStep, setActiveStep] = useState(0);
  const [showLoader, setShowLoader] = useState<boolean>(false);
  const [isInitialized, setIsInitialized] = useState(false)
  const [showCancelDialog, setShowCancelDialog] = useState<boolean>(false);
  const [showHelperDialog, setShowHelperDialog] = useState<boolean>(false);

  // parameter
  // queries und mutationen
  const { mutate: createAnfrage } = useCreateAnfrage();
  // effekte
  useEffect(() => {
    log.debug({ obj: kommune }, "Got kommune context");
    setShowHelperDialog(false);
    emptyData.kommunenName = kommune?.kommunenName ? kommune.kommunenName : "";
    emptyData.bezirksDirektion = kommune?.bdTeamName ? kommune.bdTeamName : "";
    emptyData.kuerzel = kommune?.kuerzel ? kommune.kuerzel : "";
    setIsInitialized(true);
  }, []);

  // daten
  const isLastStep = activeStep === steps.length - 1;

  // handler
  const handleSubformSubmit = (values: Anfrage, actions: FormikHelpers<Anfrage>) => {
    if (isLastStep) {
      log.debug({ obj: values }, `saving new Anfrage`);
      setShowLoader(true);
      createAnfrage(values, {
        onSuccess: () => {
          addNotification(`Anfrage wurde gespeichert`, "Hinweis");
          setShowLoader(false);
          if (props.onFormSubmit) props.onFormSubmit();
        },
        onError: () => {
          setShowLoader(false);
          actions.setSubmitting(false);
        },
      });
    } else {
      log.debug(`going one step forward in Anfrage`);
      log.trace({ obj: values }, "current form values");
      setActiveStep((prev) => prev + 1);
      actions.setSubmitting(false);
    }
  };

  const handleBack = () => {
    log.debug(`going one step back in Anfrage`);
    setActiveStep((prev) => prev - 1);
  };

  const handlePrint = () => {
    log.debug(`printing Anfrage`);
    window.print();
  };

  const handleCancel = () => {
    log.debug(`canceled Anfrage, going to home`);
    setShowCancelDialog(true);
    // history.push("/");
  };

  const handleCancelAccepted = () => {
    log.debug("Cancel accepted");
    if (props.onCancel) props.onCancel();
  };

  const showHelperModal = () => {
    setShowHelperDialog(true);
  };

  const closeHelperModal = () => {
    setShowHelperDialog(false);
  };

  if (!isInitialized) return null;

  return (
    <>
      <Container>
        <Formik
          initialValues={emptyData}
          validationSchema={stepForms[activeStep]?.schema}
          onSubmit={handleSubformSubmit}
        >
          {({ handleSubmit, isSubmitting, setErrors, setTouched }): JSX.Element => {
            useEffect(() => {
              setTouched({});
              setErrors({});
            }, [activeStep]);

            return (
              <>
                {steps[activeStep].modalHelp ? (
                  <>
                    <HelperComponent>
                      <h5>{steps[activeStep].helpMenuTitle || "So geht's"}</h5>
                      {steps[activeStep].helpMenuText && <p>{steps[activeStep].helpMenuText}</p>}
                      <Button onClick={showHelperModal}>Öffnen</Button>
                    </HelperComponent>
                    {steps[activeStep].key === "2" && (
                      <BauprojektHelp handleClose={closeHelperModal} showDialog={showHelperDialog} />
                    )}
                    {steps[activeStep].key === "3" && (
                      <LoeschwasserHelp handleClose={closeHelperModal} showDialog={showHelperDialog} />
                    )}
                  </>
                ) : null}
                <Row>
                  <Col>
                    <h4>Herzlich Willkommen!</h4>
                  </Col>
                </Row>
                <ProcessStepper steps={steps} currentStep={steps[activeStep].key} />

                <ContentBox title={stepForms[activeStep]?.caption ?? steps[activeStep]?.title}>
                  <FormikForm onSubmit={handleSubmit}>
                    {stepForms[activeStep].form}
                    <Row className="d-print-none">
                      <Col className="d-flex justify-content-between">
                        <span>
                          {activeStep !== 0 && (
                            <ActionButton
                              className="mr-3 mb-3"
                              disabled={isSubmitting}
                              caption="Zurück"
                              icon={BsArrowLeft}
                              onClick={handleBack}
                            />
                          )}
                          {isLastStep && (
                            <>
                              <Button
                                onClick={handlePrint}
                                className="mr-3 mb-3"
                                variant="secondary"
                                disabled={isSubmitting}
                              >
                                Drucken
                              </Button>
                              <Button
                                onClick={handleCancel}
                                className="mr-3 mb-3"
                                variant="secondary"
                                disabled={isSubmitting}
                              >
                                Abbrechen
                              </Button>
                            </>
                          )}
                        </span>
                        <span>
                          {isLastStep ? (
                            <>
                              <Button type="submit" className="mr-3 mb-3" disabled={isSubmitting}>
                                <BsArrowRight className="mr-2" />
                                Senden
                              </Button>
                            </>
                          ) : (
                            <ActionButton disabled={isSubmitting} type="submit" iconSide="right" />
                          )}
                        </span>
                      </Col>
                    </Row>
                    <Persist name={sessionStorageName} isSessionStorage clearIfSubmitted />
                  </FormikForm>
                </ContentBox>
              </>
            );
          }}
        </Formik>
      </Container>
      <ModalLoader show={showLoader}>Anfrage wird gespeichert, bitte warten...</ModalLoader>
      <Modal size="lg" show={showCancelDialog}>
        <Modal.Header closeButton>
          <h4>Abbrechen</h4>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col>Wollen Sie die Anfrage wirklich abbrechen? Ihr bisherigen Eingaben werden verworfen.</Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Row>
            <Col>
              <Button className="mr-3 px-4" as="button" type="submit" onClick={handleCancelAccepted}>
                Ja
              </Button>
              <Button
                variant="secondary"
                className="px-4"
                onClick={() => {
                  setShowCancelDialog(false);
                }}
              >
                Nein
              </Button>
            </Col>
          </Row>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Home;
