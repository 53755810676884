import { Kommune } from "../../domain/Types";
import { log } from "../Logger";
import * as turf from "@turf/turf";

type UseTurfReturnType = {
  checkIsWithinBoundaries: (kommuneToCheck: Kommune | null, positionToCheck?: L.LatLng) => boolean;
};

export const useTurf = (): UseTurfReturnType => {
  const checkIsWithinBoundaries = (kommuneToCheck: Kommune | null, positionToCheck?: L.LatLng): boolean => {
    log.debug(
      {
        obj: {
          kommune: kommuneToCheck,
          position: positionToCheck,
        },
      },
      "Check if current position is within defined polygon area"
    );
    if (!kommuneToCheck) return false;
    if (!kommuneToCheck.polygon || kommuneToCheck.polygon?.length === 0) return true;
    if (!positionToCheck || (kommuneToCheck.polygon.length > 0 && kommuneToCheck.polygon.length < 4)) return false;
    return areaCheck(kommuneToCheck, positionToCheck);
  };

  const areaCheck = (kommuneToCheck: Kommune, positionToCheck: L.LatLng): boolean => {
    const clickPoints = turf.points([[positionToCheck.lng, positionToCheck.lat]]);
    const polygonToCheck = turf.polygon([kommuneToCheck.polygon]);

    const pointInPolygon = turf.pointsWithinPolygon(clickPoints, polygonToCheck);
    const isWithin = pointInPolygon !== null && pointInPolygon.features.length > 0;
    log.debug(
      {
        obj: {
          isWithin: isWithin,
        },
      },
      "Clicked point is within"
    );
    return isWithin;
  };

  return {
    checkIsWithinBoundaries,
  };
};
