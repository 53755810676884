import React, { createContext, ReactElement, ReactNode, useMemo } from "react";
import axios, { AxiosRequestConfig, AxiosInstance } from "axios";
import { log } from "../common/Logger";

interface Props {
  children: ReactNode;
}

interface ApiContextProps {
  api: AxiosInstance;
}

const axiosRequestConfiguration: AxiosRequestConfig = {
  baseURL: process.env.REACT_APP_TRIGGER_URL,
  responseType: "json",
  headers: {
    "Content-Type": "application/json",
  },
};

export const ApiContext = createContext<ApiContextProps>({
  api: axios.create(),
});

export function ApiContextProvider({ children }: Props): ReactElement {
  const api = useMemo(() => {
    const axiosInstance = axios.create(axiosRequestConfiguration);
    log.debug("initializing new axios api-instance");

    // Trace-Parent für Logging mitgeben
    // TODO: sollte irgendwie pro "Aktion" generiert werden
    // axiosInstance.interceptors.request.use((value) => {
    //   value.headers.TraceParent = "12345-12345-12345";
    //   return value;
    // });

    return axiosInstance;
  }, []);

  return <ApiContext.Provider value={{ api }}>{children}</ApiContext.Provider>;
}
