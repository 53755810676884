import { FormikControl, FormSection } from "@gelsenwasser/react";
import { useFormikContext } from "formik";
import React from "react";
import { Row, Col } from "react-bootstrap";
import { log } from "../../common/Logger";
import { Anfrage, AnredeOptions, TitelOptions } from "../Types";

const KontaktdatenStep: React.VFC = () => {
  // hooks
  const { values, setFieldValue } = useFormikContext<Anfrage>();
  // states
  // parameter
  // queries und mutationen
  // effekte
  // daten
  // handler
  const handleChangeNumberSelection: React.ChangeEventHandler<HTMLInputElement | HTMLSelectElement> = (e) => {
    const fieldName = e.target.name;
    const value = e.target.value;
    log.debug(`Setting field '${fieldName}' to value '${value}'`);
    if (value && value !== "0") {
      setFieldValue(fieldName, parseInt(value, 10));
    } else {
      setFieldValue(fieldName, null);
    }
  };

  return (
    <FormSection title="">
      <Row>
        <Col md={6}>
          <FormikControl
            name="kontaktdaten.anrede"
            label="Anrede *"
            as="select"
            onChange={handleChangeNumberSelection}
            value={values.kontaktdaten.anrede ?? ""}
          >
            <option value="">(Bitte wählen)</option>
            {AnredeOptions.map((anrede, idx) => (
              <option key={`${anrede.key}_anrede_${idx}`} value={anrede.key}>
                {anrede.value}
              </option>
            ))}
          </FormikControl>
        </Col>
        <Col md={6}>
          <FormikControl
            name="kontaktdaten.titel"
            label="Titel"
            as="select"
            onChange={handleChangeNumberSelection}
            value={values.kontaktdaten.titel ?? ""}
          >
            {TitelOptions.map((titel, idx) => (
              <option key={`${titel.key}_titel_${idx}`} value={titel.key}>
                {titel.value}
              </option>
            ))}
          </FormikControl>
        </Col>
      </Row>
      <Row>
        <Col md={3}>
          <FormikControl name="kontaktdaten.vorname" label="Vorname *" />
        </Col>
        <Col md={3}>
          <FormikControl name="kontaktdaten.nachname" label="Nachname *" />
        </Col>
        <Col md={6}>
          <FormikControl name="kontaktdaten.unternehmen" label="Unternehmen" />
        </Col>
      </Row>
      <Row>
        <Col md={4}>
          <FormikControl name="kontaktdaten.strasse" label="Strasse *" />
        </Col>
        <Col md={2}>
          <FormikControl name="kontaktdaten.hausnummer" label="Hausnummer *" />
        </Col>
        <Col md={2}>
          <FormikControl name="kontaktdaten.plz" label="PLZ *" />
        </Col>
        <Col md={4}>
          <FormikControl name="kontaktdaten.ort" label="Ort *" />
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <FormikControl name="kontaktdaten.adresszusatz" label="Adresszusatz" />
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <FormikControl name="kontaktdaten.telefon" label="Telefon (empfohlen)" />
        </Col>
        <Col md={6}>
          <FormikControl name="kontaktdaten.email" label="E-Mail *" />
        </Col>
      </Row>
    </FormSection>
  );
};

export default KontaktdatenStep;
