import { AxiosError } from "axios";
import { useContext } from "react";
import { useMutation, UseMutationResult, useQueryClient } from "react-query";
import { log } from "../common/Logger";
import { useErrorLogger } from "../common/LoggerHooks";
import { Anfrage } from "../domain/Types";
import { ApiContext } from "./ApiContext";

//TODO: warum hier react-query, wo wir ja nur ein einfaches post machen müssen; könnte man an dieser stelle ja auch weglassen
export const useCreateAnfrage = (): UseMutationResult<string, AxiosError, Anfrage> => {
  const { logAxiosError } = useErrorLogger();
  const { api } = useContext(ApiContext);
  const queryClient = useQueryClient();

  const CreateAnfrage = async (data: Anfrage) => {
    log.trace({ obj: data }, "creating new Anfrage");

    const response = await api.post("", data);
    log.trace({ obj: response }, "resut from post Anfrage");
    return response.data;
  };

  return useMutation<string, AxiosError, Anfrage>(CreateAnfrage, {
    onSuccess: () => {
      queryClient.invalidateQueries("Anfrage");
      log.debug("successfully created Anfrage");
    },
    onError: (err) => logAxiosError("Error creating Anfrage", "Fehler beim Erstellen der Anfrage", err),
  });
};
