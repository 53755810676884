import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { FormikCheck, FormikControl, FormSection, NotificationType, useNotification } from "@gelsenwasser/react";
import { Row, Col, Container } from "react-bootstrap";
import { ErrorMessage, useFormikContext } from "formik";

import debounce from "lodash.debounce";
import L, { latLng, LatLng, LatLngExpression } from "leaflet";
import { MapContainer, TileLayer, Marker, useMap, useMapEvents } from "react-leaflet";

import "leaflet/dist/leaflet.css";

// im Standard wird die URL für die Icons irgendwie komisch erstellt (mit Base64 Data-URL *und* dem Icon-Namen), was zu Fehlern in der Darstellung führt
delete L.Icon.Default.prototype["_getIconUrl"];
import i1 from "leaflet/dist/images/marker-icon-2x.png";
import i2 from "leaflet/dist/images/marker-icon.png";
import i3 from "leaflet/dist/images/marker-shadow.png";
L.Icon.Default.mergeOptions({ iconRetinaUrl: i1, iconUrl: i2, shadowUrl: i3 });

import { Anfrage, defaultMapCenter, Projektstandort } from "../Types";
import { useGetAddress, useGetPosition } from "services/osmQueries";
import { log } from "common/Logger";
import { useKommune } from "../KommunenContext";
import { kommunenJsonData } from "../../common/kommuneUtil";
import GwLogo from "../../assets/gw_logo.png";
import { useTurf } from "../../common/hooks/useTurf";

const debouncedStandort = debounce((standort: Projektstandort) => standort, 500);

interface MyMapProps {
  center?: LatLngExpression;
  onPositionClicked?: (latLng: LatLng) => void;
}
const MyMap = ({ center, onPositionClicked }: MyMapProps) => {
  useMapEvents({
    click(e) {
      if (onPositionClicked) onPositionClicked(e.latlng);
    },
  });

  const map = useMap();
  if (center) {
    map.panTo(center);
  }

  return null;
};

const ProjektstandortStep: React.VFC = () => {
  // hooks
  const { touched, errors, values, setFieldValue } = useFormikContext<Anfrage>();
  const { kommune, switchKommune, switchBranding } = useKommune();
  const locationBackup = useRef<{ lat: number; lng: number }>();
  const previousAddress = useRef<{ strasse: string; hausnummer: string; plz: string; ort: string }>({
    strasse: "",
    hausnummer: "",
    plz: "",
    ort: "",
  });
  const { addNotification } = useNotification();

  // states
  const markerRef = useRef<L.Marker>(null);

  // parameter
  // queries und mutationen
  const position =
    values.projektstandort.latitude && values.projektstandort.longitude
      ? new LatLng(values.projektstandort.latitude, values.projektstandort.longitude)
      : undefined;

  const { data: positionLookup } = useGetPosition(
    debouncedStandort(values.projektstandort),
    !values.projektstandort.karteAktiv &&
      (!!values.projektstandort.hausnummer ||
        !!values.projektstandort.strasse ||
        !!values.projektstandort.ort ||
        !!values.projektstandort.plz)
  );
  const { data: addressLookup } = useGetAddress(
    position ?? defaultMapCenter,
    !!values.projektstandort.karteAktiv && !!position
  );

  const { checkIsWithinBoundaries } = useTurf();

  // effekte
  useEffect(() => {
    // default ort nach Auswahl der Kommune
    log.debug(
      {
        obj: {
          kommune: kommune?.kommunenName,
          lat: kommune?.lat,
          lng: kommune?.lng,
        },
      },
      "Selected Kommune"
    );
    if (!values.projektstandort.ort) {
      setFieldValue("projektstandort.ort", kommune?.kommunenName ?? "", false);
      setFieldValue("projektstandort.name", kommune?.name ?? "", false);
    }
  }, []);

  useEffect(() => {
    // hier wird die der Marker auf der Karte positioniert, wenn eine Adresse eingegeben wurde
    log.debug(
      {
        obj: {
          karteAktiv: values.projektstandort.karteAktiv,
          positionLookup,
          previousAddress: previousAddress.current,
        },
      },
      "running effect for positionLookup"
    );
    if (!positionLookup) return;

    if (!checkIsWithinBoundaries(kommune, positionLookup)) {
      resetToLastStandort(`Der gewählte Standort wird leider nicht versorgt.`);
    } else {
      locationBackup.current = positionLookup;
      if (
        !values.projektstandort.karteAktiv &&
        (previousAddress.current.strasse !== values.projektstandort.strasse ||
          previousAddress.current.hausnummer !== values.projektstandort.hausnummer ||
          previousAddress.current.plz !== values.projektstandort.plz ||
          previousAddress.current.ort !== values.projektstandort.ort)
      ) {
        updateProjektPosition(values, positionLookup);
      }
    }
  }, [positionLookup]);

  useEffect(() => {
    // hier wird die Adresse gespeichert, wenn der Marker bewegt wurde
    log.debug(
      {
        obj: {
          karteAktiv: values.projektstandort.karteAktiv,
          addressLookup,
        },
      },
      "running effect for addressLookup"
    );
    if (values.projektstandort.karteAktiv && addressLookup) {
      const cityOrTown = addressLookup.address.city
        ? addressLookup.address.city
        : addressLookup.address.town
        ? addressLookup.address.town
        : addressLookup.address.village;
      if (kommune?.kommunenName !== cityOrTown) {
        log.warn(`select city does not match kommune ${kommune?.kommunenName}`);

        // gibt es eine passende Kommune, wenn ja, dann die Komune wechseln
        const alternativeKommune = kommunenJsonData.find((k) => k.kommunenName === cityOrTown);
        if (alternativeKommune) {
          log.warn(`switching kommune to ${alternativeKommune.kommunenName}`);
          switchKommune(alternativeKommune);
          switchBranding({
            datenschutzLink: alternativeKommune.datenschutzLink || "",
            gesellschaftsName: alternativeKommune.gesellschaftsName || "",
            impressumLink: alternativeKommune.impressumLink || "",
            kontaktEmail: alternativeKommune.kontaktEmail || "",
            logo: `./logos/${alternativeKommune.logo}` || GwLogo,
          });
          if (!checkIsWithinBoundaries(alternativeKommune, position)) {
            resetToLastStandort(`Der gewählte Standort wird leider nicht versorgt.`);
            return;
          }
        } else {
          // reset auf die zuletzt bekannten Koordinaten
          resetToLastStandort(`"${cityOrTown}" passt nicht zu der ausgewählten Kommune "${kommune?.kommunenName}"`);
          return;
        }
      } else if (!checkIsWithinBoundaries(kommune, position)) {
        resetToLastStandort(`Der gewählte Standort wird leider nicht versorgt.`);
        return;
      }

      const neuerStandort = {
        ...values.projektstandort,
        strasse: addressLookup.address.road ?? "",
        hausnummer: addressLookup.address.house_number ?? "",
        plz: addressLookup.address.postcode ?? "",
        ort: cityOrTown ?? "",
      };

      setFieldValue("projektstandort", neuerStandort, false);
      // hier die aktuellen Koordinaten zwischenspeichern
      locationBackup.current = { lat: neuerStandort.latitude, lng: neuerStandort.longitude };
      // adresse merken
      previousAddress.current = {
        strasse: neuerStandort.strasse,
        hausnummer: neuerStandort.hausnummer,
        plz: neuerStandort.plz,
        ort: neuerStandort.ort,
      };
    }
  }, [addressLookup]);

  const resetToLastStandort = (notificationMsg: string) => {
    const alterStandort = {
      ...values.projektstandort,
      latitude: locationBackup.current?.lat,
      longitude: locationBackup.current?.lng,
    };
    addNotification(notificationMsg, "Außerhalb des Versorgungsgebiets", NotificationType.Alert);
    setFieldValue("projektstandort", alterStandort, false);
  };

  // daten
  // handler
  const updateProjektPosition = useCallback(
    (values: Anfrage, position?: LatLng) => {
      const neuerStandort = {
        ...values.projektstandort,
        latitude: position?.lat,
        longitude: position?.lng,
      };
      log.debug({ obj: neuerStandort }, "updating projektstandort");
      setFieldValue("projektstandort", neuerStandort, false);
    },
    [setFieldValue]
  );

  const eventHandlers: L.LeafletEventHandlerFnMap = useMemo(
    () => ({
      dragend() {
        if (!values.projektstandort.karteAktiv) return;
        const marker = markerRef.current;
        if (marker != null) {
          const newPosition = marker.getLatLng();
          log.debug({ obj: newPosition }, "got new position, by dragging the marker");

          updateProjektPosition(values, newPosition);
        }
      },
    }),
    [values.projektstandort.karteAktiv]
  );

  const handlePositionClicked = (latLng: LatLng) => {
    if (!values.projektstandort.karteAktiv) return;
    log.debug({ obj: latLng }, "got new position, by clicking the map");

    updateProjektPosition(values, latLng);
  };

  return (
    <FormSection title="">
      <p>
        Fügen Sie Ihren Standort als Adressdaten ein. Wenn keine Adresse vorhanden ist, fügen Sie ihre Flurnummer ein.
        Sie können auch alternativ die Karte benutzen und den Pin auf Ihren Standort verschieben/setzen.
      </p>
      <Row>
        <Col md={6}>
          <Container>
            <Row>
              <Col md={8}>
                <FormikControl
                  name="projektstandort.strasse"
                  label="Strasse"
                  disabled={values.projektstandort.karteAktiv}
                />
              </Col>
              <Col>
                <FormikControl
                  name="projektstandort.hausnummer"
                  label="Hausnummer"
                  disabled={values.projektstandort.karteAktiv}
                />
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <FormikControl name="projektstandort.plz" label="PLZ" disabled={values.projektstandort.karteAktiv} />
              </Col>
              <Col>
                <FormikControl name="projektstandort.name" label="Ort" disabled />
              </Col>
            </Row>
            <Row>
              <Col>
                <FormikCheck
                  type="switch"
                  label="Karte benutzen"
                  value="false"
                  checked={values.projektstandort.karteAktiv ?? false}
                  name="projektstandort.karteAktiv"
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <strong>Zusatzinformation</strong>
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <FormikControl name="projektstandort.flur" label="Flur" />
              </Col>
              <Col>
                <FormikControl name="projektstandort.flurstueck" label="Flurstück" />
              </Col>
            </Row>
            {touched.projektstandort?.flur &&
              touched.projektstandort?.flurstueck &&
              errors.projektstandort &&
              typeof errors.projektstandort === "string" && (
                <Row>
                  <Col>
                    <small style={{ color: "red" }}>
                      <ErrorMessage name="projektstandort" />
                    </small>
                  </Col>
                </Row>
              )}
          </Container>
        </Col>
        <Col>
          <MapContainer
            center={kommune ? latLng(kommune.lat, kommune.lng) : defaultMapCenter}
            zoom={13}
            style={{ height: "100%", minHeight: "300px" }}
          >
            <MyMap center={position} onPositionClicked={handlePositionClicked} />
            <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            {position && (
              <Marker
                position={position}
                ref={markerRef}
                eventHandlers={eventHandlers}
                draggable={values.projektstandort.karteAktiv}
              />
            )}
          </MapContainer>
        </Col>
      </Row>
    </FormSection>
  );
};

export default ProjektstandortStep;
